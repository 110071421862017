import React from 'react'
import Modules from 'src/components/Modules'

export const RenderModules = (modules, locale) => {
  if (modules) {
    return modules.map((module) => {
      return (
        <React.Fragment key={module._key}>
          <Modules type={module._type} reactModule={module} locale={locale} />
        </React.Fragment>
      )
    })
  }
}
