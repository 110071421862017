import React from 'react'
import { compose, get, map } from 'lodash/fp'
import { graphql } from 'gatsby'
import { RenderModules } from 'src/utils/renderModules'
import SEO from 'src/components/SEO'
import { localize } from 'src/components/Localize'
import Layout from 'src/components/Layouts'

const Page = ({ path, pageContext, preview = false, data }) => {
  const {
    main: { modules, title, slug },
    siteGlobal,
    meta,
    locale,
  } = pageContext

  const menuItems = compose(map('node'), get('allSanityMenus.edges'))(data)

  const navMenuItems = menuItems.map((menuItem) => ({
    ...menuItem,
    items: menuItem.items.map((item) => ({
      ...item,
      link: get('link.content.main.slug.current', item),
    })),
  }))

  let url

  if (locale === 'tr') {
    if (slug.current === 'anasayfa') url = ''
    url = `/${locale}${path}`
  } else if (locale === 'no') {
    url = path
  }

  return (
    <Layout navMenuItems={navMenuItems} siteGlobal={siteGlobal} locale={locale}>
      {preview && <div className="bcblue ac cw x p1">This is a Preview</div>}
      <SEO title={title} metaInfo={meta} pagePath={url} />
      {RenderModules(modules, locale)}
    </Layout>
  )
}
// @ts-ignore
export default localize(Page)

export const query = graphql`
  query PageTemplateQuery {
    allSanitySiteGlobal {
      edges {
        node {
          _rawContent
        }
      }
    }
    allSanityMenus {
      edges {
        node {
          menuLocation {
            mainMenu
            footerMenu
          }
          items {
            ... on SanityInternalLink {
              title {
                no
                tr
                _type
              }
              link {
                content {
                  main {
                    slug {
                      _type
                      tr {
                        current
                        _type
                      }
                      no {
                        current
                        _type
                      }
                    }
                  }
                }
              }
            }
          }
          title {
            no
            tr
            _type
          }
          slug {
            _type
            tr {
              current
              _type
            }
            no {
              current
              _type
            }
          }
        }
      }
    }
  }
`
